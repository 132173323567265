import React, { Fragment, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import AccessTimeIcon from "@material-ui/icons/AccessTime";

// Formik
import { useFormik } from "formik";

// Material UI Components
import { Alert } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import Button from "@material-ui/core/Button";
import Fade from "@material-ui/core/Fade";
import Grid from "@material-ui/core/Grid";
import Modal from "@material-ui/core/Modal";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { TimePicker } from "@material-ui/pickers";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { KeyboardTimePicker } from "@material-ui/pickers";
import { KeyboardDatePicker, DateTimePicker } from "@material-ui/pickers";

// Icons
import CloseIcon from "@material-ui/icons/Close";

// API
import { SDCloudBackendAPIWithToken } from "../../../../apis/backendAPI";
import { Api } from "../../../../config/api";
import moment from "moment-timezone";

// Local Imports
import LoaderHOC from "components/HOC/LoaderHoc";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import GridItem from "../../../../components/Grid/GridItem";
import Axios from "axios";
import ConfirmationModal from "views/Design/Components/ConfirmationDelete";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex"
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    // border: "2px solid #000",
    // boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: 600,
    borderRadius: "0"
  },
  button: {
    margin: "30px 0 40px"
  },
  btnColor: {
    background: theme.palette.buttonColor.main,
    "&:hover": {
      background: theme.palette.buttonColor.main
    }
  },
  title: {
    margin: "20px 0",
    textTransform: "capitalize"
  },
  CloseIcon: {
    color: "#ffffff ",
    cursor: "pointer",
    marginLeft: "10px"
  },
  helperText: {
    marginTop: "10px",
    color: "#7D7D7D"
  },
  helperCustom: {
    color: "#7D7D7D",
    margin: "10px 0 1px"
  },
  ipAddressDropdown: {
    width: "100%",
    marginTop: "5px"
  },
  purposeDropdown: {
    width: "100%",
    marginTop: "5px"
  },
  zoneDropdown: {
    width: "100%",
    marginTop: "5px"
  },
}));

const inputProps = {
  disableAnimation: true,
  shrink: false
};

const formControlProps = {
  root: {
    marginTop: "10px"
  }
};

function SandboxScheduleModal(props) {
  const [showNotification, setShowNotification] = useState(
    props?.setData?.showNotification
  );
  const [notificationMessage, setNotificationMessage] = useState(
    props?.setData?.notificationMessage
  );
  const [notificationType, setNotificationType] = useState(
    props?.setData?.notificationMessage
  );
  const [showLoader, setShowLoader] = useState(false);
  const [startTime, setstartTime] = useState(null);
  const [startDate, setstartDate] = useState(null);
  const classes = useStyles();
  const [error, setError] = React.useState(false);
  const [purpose, setPurpose] = useState(null);
  const [zone, setZone] = useState('North_America');
  const [menuItemsVals1, setMenuItemsVals1] = useState([]);
  const [menuItemsVals2, setMenuItemsVals2] = useState([]);
  const [menuItems, setMenuItems] = useState([]);
  const [ipItems, setIpItems] = useState([]);
  let domain = JSON.parse(localStorage.getItem("internal_domains"))?.domains;
  let userEmail = JSON.parse(localStorage.getItem("profileData"))?.email;
  let hasDomain = domain?.some(res => userEmail.includes(res));
  const [isInternal, setIsInternal] = useState(hasDomain);
  const [confirmModal, setConfirmModal] = useState(false);
  const [inputData, setInputData] = useState({});
  const env = localStorage.getItem("ENVIRONMENT");
  useEffect(() => {
    let available_purposes = JSON.parse(
      localStorage.getItem("available_purposes") 
    );
    if (isInternal) {
      if (available_purposes?.internal) {
        let purposes = available_purposes.internal.map(item => {
          return <option value={item}>{item}</option>;
        });
        setMenuItems([<option value={null}>None</option>, ...purposes]);
      }
      var deal_id = JSON.parse(localStorage.getItem("deal_ids"))
      if(deal_id) {
        let deals = deal_id.map(item => {
          return <option value={item}>{item}</option>;
        });
        setMenuItemsVals2([<option value={null}>None</option>, ...deals]);
      }
    } else {
      if (available_purposes?.external) {
        let purposes = available_purposes.external.map(item => {
          return <option value={item}>{item}</option>;
        });
        setMenuItems([<option value={null}>None</option>, ...purposes]);
      }
    }
    let available_zone = JSON.parse(
      localStorage.getItem("available_zones")
    );
    let available_zones=Object.keys(available_zone)
    if (available_zones) {
      let zones = available_zones.map(item => {
        return <option value={item}>{item}</option>;
      }); 
      setMenuItemsVals1([...zones]);
    }
    if (props.reservedIP?.hasOwnProperty("reservedIps")) {
      let ipAddresses = props.reservedIP.reservedIps.map(item => {
        return <option value={item}>{item}</option>;
      });
      setIpItems([<option value={null}>None</option>, ...ipAddresses]);
    }
    setShowNotification(false);
    setNotificationMessage("");
    setNotificationType("");
  }, [props.setData]);

  const handleChange=(event) => {
    setZone(event.target.value)
    setShowNotification(false);
    if(event.target.value !== 'North_America'){
      setShowNotification(true);
      setNotificationType("warning");
      setNotificationMessage("By default, the lowest cost zone is set. If you switch, it will cost more.");
    }
  }

  const setConfirmModalClose = () => {
    setConfirmModal(false);
  };

  const ConfirmModalSubmit = () => {
    setConfirmModal(false);
    setShowLoader(true);
    Axios.post(
      Api.delScheduleCluster,
      { uuid: props.uuid },
      {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("user")}`
        }
      }
    )
      .then(() => {
        // required datetime in format '%Y-%m-%d %H:%M'
        const formattedDateTime = `${moment(
          inputData.startDate + " " + inputData.startTime
        )
          .tz(moment.tz.guess())
          .utc()
          .format("YYYY-MM-DD HH:mm")}`;

        var ends = `${moment(inputData.startDate + " " + inputData.startTime)
          .add(inputData.endTime, "hours")
          .tz(moment.tz.guess())
          .utc()
          .format("YYYY-MM-DD HH:mm")}`;
        let auto_terminate;
        if (inputData.action == "terminate") {
          auto_terminate = "True";
        } else {
          auto_terminate = "False";
        }
        let instance_type;
        if (inputData.instance == "standard") {
          instance_type = "standard";
        } else {
          instance_type = "spot";
        }
        let archive;
        if(isInternal){
          if (inputData.archive == "archive") {
            archive = "archive";
          } else {
            archive = "unarchive";
          }
        } else {
          archive = "archive"
        }
        let zoneValue = ''
        if(isInternal){
          zoneValue = ''
        } else {
          zoneValue = zone
        }
        let input_action;
        if(props.action){
          input_action = "resume"
        } else {
          input_action = "schedule"
        }
        const data = {
          clustername: inputData.name,
          resource_type: "poc",
          identifier: props.labScheduleData.identifier,
          url: `${Api.baseURL}/`,
          start_time: formattedDateTime,
          purpose: purpose,
          sandbox_suspend_time: ends,
          auto_terminate: auto_terminate,
          input_action: input_action,
          instance_type: instance_type,
          archive:archive,
          clusteruuid:props.uuid
          // zone:zoneValue,
        };
        SDCloudBackendAPIWithToken()
          .post(Api.scheduleCluster, data)
          .then(res => {
            let message = res?.data?.message;
            props.refetch();
            setShowLoader(false);
            setShowNotification(true);
            setNotificationMessage(message);
            setNotificationType("success");
            // hideModal();
          })
          .catch(err => {
            let message = err?.response?.data?.message;
            setShowNotification(true);
            setNotificationMessage(message);
            setNotificationType("error");
            setShowLoader(false);
          });
      })
      .catch(err => {
        let message = err?.response?.data?.message;
        setShowNotification(true);
        setNotificationMessage(message);
        setNotificationType("error");
        setShowLoader(false);
      });
  };

  const formik = useFormik({
    initialValues: {
      name: props?.initialValues?.name ?? "",
      endTime: props?.initialValues?.endTime ?? "",
      startDate:
        props?.initialValues?.run_time === null
          ? null
          : moment(props?.initialValues?.run_time).format("MM/DD/YYYY"),
      startTime:
        props?.initialValues?.run_time === null
          ? null
          : moment(props?.initialValues?.run_time).format("HH:mm"),
      purpose: "",
      // zone:"",
      instance:"standard",
      action: "terminate",
      ip_type: "default",
      ip_address: "",
      archive:"unarchive",
    },
    onSubmit: values => {
      if (props.mode === "Edit") {
        setConfirmModal(true);
        setInputData(values);
      } else {
        setShowLoader(true);

        // required datetime in format '%Y-%m-%d %H:%M'
        const formattedDateTime = `${moment(
          values.startDate + " " + values.startTime
        )
          .tz(moment.tz.guess())
          .utc()
          .format("YYYY-MM-DD HH:mm")}`;
        var ends = `${moment(values.startDate + " " + values.startTime)
          .add(values.endTime, "hours")
          .tz(moment.tz.guess())
          .utc()
          .format("YYYY-MM-DD HH:mm")}`;
        let auto_terminate;
        if (values.action == "terminate") {
          auto_terminate = "True";
        } else {
          auto_terminate = "False";
        }
        let zoneValue = ''
        if(isInternal){
          zoneValue = ''
        } else {
          zoneValue = zone
        }
        let is_archive;
        if (isInternal){
          is_archive = values.archive
        } else {
          is_archive = "archive"
        }
        let input_action;
        let clustername;
        if(props.action){
          input_action = "resume"
          clustername = props.name
        } else {
          input_action = "schedule"
          clustername = values.name
        }
        const payload = {
          clustername: clustername,
          resource_type: "poc",
          auto_terminate: auto_terminate,
          purpose: values.purpose,
          // zone:zoneValue,
          instance_type:values.instance,
          archive: is_archive,
          sandbox_suspend_time: ends,
          identifier: props.identifier,
          url: `${Api.baseURL}/`,
          start_time: formattedDateTime,
          input_action: input_action,
          clusteruuid:props.uuid
        };
        if (values.ip_type == "reserved" && values.ip_address != "") {
          payload["reserved_ip"] = true;
          payload["ip_address"] = values.ip_address;
        }
        SDCloudBackendAPIWithToken()
          .post(Api.scheduleCluster, payload)
          .then(resp => resp.data)
          .then(data => {
            let message = data.message;
            setShowNotification(true);
            setNotificationType("success");
            setNotificationMessage(message);
            setShowLoader(false);
          })
          .catch(err => {
            let message = err.response.data.message;
            setShowNotification(true);
            setNotificationType("error");
            setNotificationMessage(message);
            setShowLoader(false);
          });
      }
    },
    validate: values => {
      let errors = {};
      if (!values.name && !props.action) {
        errors.name = "Required";
      }
      if (!values.endTime || values.endTime < 1 || values.endTime > 336) {
        errors.endTime = "Required";
      }
      if (isInternal && env!=="production" && values.endTime > 2){
        errors.endTime = "Maximum time allowed for internal users is 2 hours."
      }
      if (!values.startDate) {
        errors.startDate = "Required";
      }

      if (values.ip_type === "reserved" && !values.ip_address) {
        errors.ip_address = "Required";
      }
      if (!values.startTime) {
        errors.startTime = "Required";
      }

      if ((values.purpose == "" || values.purpose == "None") && isInternal) {
        errors.purpose = "Required";
      } else if (values.purpose == "" && !isInternal) {
        values.purpose = JSON.parse(
          localStorage.getItem("available_purposes")
        ).external[0];
      }
      if (values.purpose == "Mint" && (values.deal_id=="" || values.deal_id == "None")){
        errors.deal_id = "Required";
      }
      // if (values.zone == "" || values.zone == "None"){
      //   errors.zone = "Required";
      // }

      return errors;
    }
  });

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={props.open}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
        disableBackdropClick
      >
        <Fade in={props.open}>
          <div className={classes.root}>
            <Paper elevation={0} className={classes.paper}>
              <Typography variant="h4" className={classes.title}>
                Schedule {props.title}
              </Typography>
              <Typography variant="body2" className={classes.helperText}>
                Please fill all the details
              </Typography>
              <form onSubmit={formik.handleSubmit}>
                <Typography variant="body2" className={classes.helperCustom}>
                  Name
                </Typography>
                {props.action === "resume"?(
                  <TextField
                  type="text"
                  variant="filled"
                  name="name"
                  fullWidth
                  disabled={true}
                  title="This field is not editable"
                  InputLabelProps={inputProps}
                  InputProps={{ disableUnderline: true }}
                  value={props.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                ):(
                formik.errors.name && formik.touched.name ? (
                  <TextField
                    type="text"
                    variant="filled"
                    name="name"
                    fullWidth
                    InputLabelProps={inputProps}
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    helperText={formik.errors.name}
                    onBlur={formik.handleBlur}
                    error
                  />
                ) : (
                  <TextField
                    type="text"
                    variant="filled"
                    name="name"
                    fullWidth
                    InputLabelProps={inputProps}
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                )
                )}
                <Grid container>
                  {isInternal ? (
                    <Grid item xs={12}>
                      <Typography
                        variant="body2"
                        className={classes.helperCustom}
                      >
                        Purpose
                      </Typography>
                      {
                        <FormControl
                          variant="filled"
                          className={
                            (classes.formControl, classes.purposeDropdown)
                          }
                        >
                          {formik.errors.purpose && formik.touched.purpose ? (
                            <Select
                              labelId="demo-simple-select-filled-label"
                              id="demo-simple-select-filled"
                              fullWidth
                              name="purpose"
                              value={formik.values.purpose}
                              onChange={formik.handleChange}
                              native
                              error
                              helperText={formik.errors.purpose}
                              className={classes.purposeDropdown}
                            >
                              {menuItems.length > 0 ? (
                                menuItems
                              ) : (
                                <MenuItem value="">None</MenuItem>
                              )}
                            </Select>
                          ) : (
                            <Select
                              labelId="demo-simple-select-filled-label"
                              id="demo-simple-select-filled"
                              fullWidth
                              name="purpose"
                              value={formik.values.purpose}
                              onChange={event => {
                                setPurpose(event.target.value);
                                formik.setFieldValue(
                                  "purpose",
                                  event.target.value
                                );
                              }}
                              // onChange={formik.handleChange}
                              native
                              className={classes.purposeDropdown}
                            >
                              {menuItems.length > 0 ? (
                                menuItems
                              ) : (
                                <MenuItem value="">None</MenuItem>
                              )}
                            </Select>
                          )}
                        </FormControl>
                      }
                    </Grid>
                  ) : null}
                  {isInternal && purpose == "Mint" ? (
                    <Grid item xs={12}>
                      <Typography
                        variant="body2"
                        className={classes.helperCustom}
                      >
                        Select Deal Id
                      </Typography>
                      {
                                  <FormControl
                                      variant="filled"
                                      className={
                                        (classes.formControl,
                                            classes.purposeDropdown)
                                      }
                                  >
                                    {formik.errors.deal_id &&
                                    formik.touched.deal_id ? (
                                        <Select
                                            labelId="demo-simple-select-filled-label"
                                            id="demo-simple-select-filled"
                                            fullWidth
                                            name="deal_id"
                                            value={formik.values.deal_id}
                                            onChange={formik.handleChange}
                                            native
                                            error
                                            helperText={formik.errors.deal_id}
                                            className={classes.purposeDropdown}
                                        >
                                          {menuItemsVals2.length > 0 ? (
                                              menuItemsVals2
                                          ) : (
                                              <MenuItem value="">None</MenuItem>
                                          )}
                                        </Select>
                                    ) : (
                                        <Select
                                            labelId="demo-simple-select-filled-label"
                                            id="demo-simple-select-filled"
                                            fullWidth
                                            name="deal_id"
                                            value={formik.values.deal_id}
                                            onChange={formik.handleChange}
                                            native
                                            className={classes.purposeDropdown}
                                        >
                                          {menuItemsVals2.length > 0 ? (
                                              menuItemsVals2
                                          ) : (
                                              <MenuItem value="">None</MenuItem>
                                          )}
                                        </Select>
                                    )}
                                  </FormControl>
                                }
                    </Grid>
                  ) : null}
                  {/* {!isInternal ? (
                  <Grid item xs={12}>
                      <Typography
                        variant="body2"
                        className={classes.helperCustom}
                      >
                        Zone
                      </Typography>
                      {
                        <FormControl
                          variant="filled"
                          className={
                            (classes.formControl,
                            classes.zoneDropdown)
                          }
                        >
                            <Select
                              labelId="demo-simple-select-filled-label"
                              id="demo-simple-select-filled"
                              fullWidth
                              name="zone"
                              value={zone}
                              onChange={handleChange}
                              native
                              className={classes.zoneDropdown}
                            >
                              {menuItemsVals1.length > 0 ? (
                                menuItemsVals1
                              ) : (
                                <MenuItem value="">None</MenuItem>
                              )}
                            </Select>
                        </FormControl>
                      }
                    </Grid>
                  ) : null}  */}
                  <Grid item xs={12}>
                    <Typography
                      variant="body2"
                      className={classes.helperCustom}
                    >
                      Number of Hours
                    </Typography>
                    {formik.errors.endTime && formik.touched.endTime ? (
                      <TextField
                        type="number"
                        variant="filled"
                        name="endTime"
                        fullWidth
                        InputLabelProps={inputProps}
                        value={formik.values.endTime}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error
                      />
                    ) : (
                      <TextField
                        type="number"
                        variant="filled"
                        name="endTime"
                        fullWidth
                        InputLabelProps={inputProps}
                        value={formik.values.endTime}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    )}
                  </Grid>
                  {isInternal && env!=="production" && props.action !== "resume"?
                  (<Grid item xs={12}>
                              <Typography
                                variant="body2"
                                className={classes.helperCustom}
                              >
                                Instance type
                              </Typography>

                              <FormControl component="fieldset">
                                <RadioGroup
                                  row
                                  aria-label="ip_type"
                                  name="instance"
                                  value={formik.values.instance}
                                  className={classes.radioButton}
                                  onChange={formik.handleChange}
                                >
                                  <FormControlLabel
                                    value="standard"
                                    control={<Radio color="primary" />}
                                    label="Standard"
                                  />
                                  <FormControlLabel
                                    value="spot"
                                    control={<Radio color="primary" />}
                                    label="Spot"
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Grid>
                  ) : null}
                  {isInternal ?
                  (<Grid item xs={12}>
                              <Typography
                                variant="body2"
                                className={classes.helperCustom}
                              >
                                Archive
                              </Typography>

                              <FormControl component="fieldset">
                                <RadioGroup
                                  row
                                  aria-label="ip_type"
                                  name="archive"
                                  value={formik.values.archive}
                                  className={classes.radioButton}
                                  onChange={formik.handleChange}
                                >
                                  <FormControlLabel
                                    value="archive"
                                    control={<Radio color="primary" />}
                                    label="Yes"
                                  />
                                  <FormControlLabel
                                    value="unarchive"
                                    control={<Radio color="primary" />}
                                    label="No"
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Grid>
                  ) : null}
                  {
                    <Grid item xs={6}>
                      <Typography
                        variant="body2"
                        className={classes.helperCustom}
                      >
                        End action
                      </Typography>

                      <FormControl component="fieldset">
                        <RadioGroup
                          row
                          aria-label="ip_type"
                          name="action"
                          value={formik.values.action}
                          className={classes.radioButton}
                          onChange={formik.handleChange}
                        >
                          <FormControlLabel
                            value="suspend"
                            control={<Radio color="primary" />}
                            label="Suspend"
                          />
                          <FormControlLabel
                            value="terminate"
                            control={<Radio color="primary" />}
                            label="Terminate"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                  }
                  {props.reservedIP?.ipService ? (
                    <Grid item xs={6}>
                      <Typography
                        variant="body2"
                        className={classes.helperCustom}
                      >
                        IP Address Type
                      </Typography>

                      <FormControl component="fieldset">
                        <RadioGroup
                          row
                          aria-label="ip_type"
                          name="ip_type"
                          value={formik.values.ip_type}
                          className={classes.radioButton}
                          onChange={formik.handleChange}
                        >
                          <FormControlLabel
                            value="default"
                            control={<Radio color="primary" />}
                            label="Default"
                          />
                          {/* {props.reservedIP.staticIp ? (
                                    <FormControlLabel
                                      value="static"
                                      control={<Radio color="primary" />}
                                      label="Static"
                                    />
                                  ) : null} */}
                          {props.reservedIP?.reservedIps ? (
                            <FormControlLabel
                              value="reserved"
                              control={<Radio color="primary" />}
                              label="Static"
                            />
                          ) : null}
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                  ) : null}

                  <Grid item xs={12}>
                    {formik.values.ip_type === "reserved" ? (
                      <FormControl
                        variant="filled"
                        className={
                          (classes.formControl, classes.ipAddressDropdown)
                        }
                      >
                        {formik.errors.ip_address &&
                        formik.touched.ip_address ? (
                          <Select
                            labelId="demo-simple-select-filled-label"
                            id="demo-simple-select-filled"
                            fullWidth
                            name="ip_address"
                            value={formik.values.ip_address}
                            onChange={formik.handleChange}
                            label="Select IP Address"
                            native
                            error
                            helperText={formik.errors.ip_address}
                            className={classes.ipAddressDropdown}
                          >
                            {ipItems.length > 0 ? (
                              ipItems
                            ) : (
                              <MenuItem value="">None</MenuItem>
                            )}
                          </Select>
                        ) : (
                          <Select
                            labelId="demo-simple-select-filled-label"
                            id="demo-simple-select-filled"
                            label="Select IP Address"
                            fullWidth
                            name="ip_address"
                            value={formik.values.ip_address}
                            onChange={formik.handleChange}
                            native
                            className={classes.ipAddressDropdown}
                          >
                            {ipItems.length > 0 ? (
                              ipItems
                            ) : (
                              <MenuItem value="">None</MenuItem>
                            )}
                          </Select>
                        )}
                      </FormControl>
                    ) : null}
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body2" className={classes.helperText}>
                      The time is in local computer timezone.
                    </Typography>
                    <Typography variant="body2" className={classes.helperText}>
                      Start Date
                    </Typography>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <Fragment>
                        <KeyboardDatePicker
                          inputVariant="filled"
                          // label="Start Date"
                          name="startDate"
                          format="MM/dd/yyyy"
                          placeholder="MM/DD/YYYY"
                          fullWidth
                          //InputLabelProps={inputProps}
                          // value={startDate}
                          labelFunc={() =>
                            formik.values.startDate || "MM/DD/YYYY"
                          }
                          //onChange={formik.handleChange}
                          onChange={val => {
                            setstartDate(moment(val).format("MM/DD/YYYY"));
                            formik.setFieldValue(
                              "startDate",
                              moment(val).format("MM/DD/YYYY")
                            );
                          }}
                          helperText={formik.errors.startDate}
                          onBlur={formik.handleBlur}
                          error={
                            formik.errors.startDate && formik.touched.startDate
                              ? true
                              : false
                          }
                        />
                      </Fragment>
                    </MuiPickersUtilsProvider>

                    {/* <TextField
                      type="date"
                      variant="filled"
                      label="Start Date"
                      name="startDate"
                      fullWidth
                      InputLabelProps={inputProps}
                      value={formik.values.startDate}
                      onChange={formik.handleChange}
                      helperText={formik.errors.startDate}
                      onBlur={formik.handleBlur}
                      error={
                        formik.errors.startDate && formik.touched.startDate
                          ? true
                          : false
                      }
                    />   */}
                    <Typography variant="body2" className={classes.helperText}>
                      Start Time
                    </Typography>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <Fragment>
                        <KeyboardTimePicker
                          fullWidth
                          ampm={false}
                          inputVariant="filled"
                          // label="Start Time"
                          placeholder="HH:MM"
                          mask="__:__"
                          name="startTime"
                          // value={startTime}
                          labelFunc={() => formik.values.startTime || "HH:MM"}
                          onChange={val => {
                            setstartTime(moment(val).format("HH:mm"));
                            formik.setFieldValue(
                              "startTime",
                              moment(val).format("HH:mm")
                            );
                          }}
                          helperText={formik.errors.startTime}
                          error={
                            formik.errors.startTime && formik.touched.startTime
                              ? true
                              : false
                          }
                          onBlur={formik.handleBlur}
                          keyboardIcon={<AccessTimeIcon />}
                        />
                      </Fragment>
                    </MuiPickersUtilsProvider>
                    {/* <TextField
                      type="time"
                      variant="filled"
                      label="Start Time"
                      name="startTime"
                      fullWidth
                      InputLabelProps={inputProps}
                      value={formik.values.startTime}
                      onChange={formik.handleChange}
                      helperText={formik.errors.startTime}
                      onBlur={formik.handleBlur}
                      error={
                        formik.errors.startTime && formik.touched.startTime
                          ? true
                          : false
                      }
                    /> */}
                  </Grid>
                </Grid>
                <LoaderHOC isLoading={showLoader}>
                  <Button
                    variant="contained"
                    color="secondary"
                    disableElevation
                    size="large"
                    fullWidth
                    disableFocusRipple
                    type="submit"
                    className={classes.button + " " + classes.btnColor}
                  >
                    Schedule
                  </Button>
                </LoaderHOC>
              </form>
              {showNotification ? (
                <Alert
                  onClose={() => setShowNotification(false)}
                  severity={notificationType}
                >
                  {notificationMessage}
                </Alert>
              ) : null}
            </Paper>
            <CloseIcon
              className={classes.CloseIcon}
              onClick={() => {
                props.onClose(false);
                formik.resetForm();
                setstartTime("");
                setstartDate("");
              }}
            />
            <ConfirmationModal
              visible={confirmModal}
              onClose={setConfirmModalClose}
              onConfirm={ConfirmModalSubmit}
              message="Are you sure want to reschedule?"
              font={classes.font}
            />
          </div>
        </Fade>
      </Modal>
    </div>
  );
}

export default SandboxScheduleModal;
