import React, { useEffect, useState } from "react";
import { useFormik } from "formik";

// Material UI Components
import { Alert } from "@material-ui/lab";
import Box from "@material-ui/core/Box";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Typography from "@material-ui/core/Typography";
import { DropzoneArea } from "material-ui-dropzone";
import LinearProgress from "@material-ui/core/LinearProgress";

import InputLabel from "@material-ui/core/InputLabel";

// Icons
import Visibility from "@material-ui/icons/Visibility";
// Local
import LoaderHoc from "../../../components/HOC/LoaderHoc";
import {
  SDCloudBackendAPIWithToken,
  SDCloudCustomizeAPIWithToken,
} from "../../../apis/backendAPI";
import { IconButton } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { Api } from "../../../config/api";
import {
  HIDE_LOADER,
  SHOW_LOADER,
} from "../../../action_creators/actioncreator";
import { useDispatch } from "react-redux";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CustomTable from "../../Usermanagement/components/Table";
import GridContainer from "../../../components/Grid/GridContainer";
import Loader from "react-loader-spinner";
import { LOADER_TYPE } from "../../../global_constants/app_constants";
import theme from "../../../theme";
import GridItem from "../../../components/Grid/GridItem";
import { async } from "@firebase/util";
import { CMSAPIWithToken } from "../../../apis/CMS";
import { set } from "date-fns";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";


// Styling
const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "50px",
  },
  item: {
    textAlign: "end",
  },
  button: {
    background: `${theme.palette.buttonColor.main} !important`,
    height: "40px",
    color: "white",
    border: "none",
    width: "200px",
    fontSize: "0.875rem",
    fontFamily: `${theme.palette.fontList.selectedFont} !important`,
    cursor: "pointer",
  },
  filter: {
    marginTop: "2%",
    padding: "20px 50px 50px 50px",
    background: "#FFFFFF",
    border: "1px solid #F0F0F0",
    boxSizing: "border-box",
    boxShadow: "0px 4px 12px rgb(0 0 0 / 4%)",
    position: "relative",
    width: "80%",
  },
  buttongrid: {
    marginTop: "20px",
  },
  btnStyle: {
    background: `#FFFFFF`,
    height: "40px",
    width: "200px",
    fontSize: "0.875rem",
    fontFamily: `${theme.palette.fontList.selectedFont} !important`,
    cursor: "pointer",
  },
  filterButton: {
    padding: "8px 50px 0px 0px",
  },
  formControl: {
    display: "flex",
    marginBottom: "40px",
    "& .MuiDropzoneArea-root": {
      minHeight: "80px !important",
      // border: "none",
      // marginRight: "20px"
    },
    "& .MuiDropzoneArea-text": {
      marginTop: "0px !important",
    },
  },
  icon: {
    marginTop: "30px",
    // paddingLeft: "80px",
    // [theme.breakpoints.down("md")]: {
    //   marginTop: "26px",
    //   paddingLeft: "40px",
    // },
  },
  progressBar: {
    backgroundColor: theme.palette.grey[300],
  },
  barColorPrimary: {
    backgroundColor: `${theme.palette.secondary.main} !important`,
  },
  progressBox: {
    marginTop: "30px",
  },
  progressBoxLabel: {
    padding: "0% 3% 3% 0% !important",
  },
}));

const Watermarking = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  // States
  const [selectedFile, setSelectedFile] = useState(null);
  const [progress, setProgress] = useState();
  const [currentUser, setCurrentUser] = useState("");
  const [signUpStatus, setSignUpStatus] = useState("Open");
  const [profileList, setProfileList] = useState({});
  const [notificationType, setNotificationType] = useState("");
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [menuItems, setMenuItems] = useState([]);
  const [pocMenuItems, setPocMenuItems] = useState([]);
  const [categorytype, setCategorytype] = useState([]);
  const [pocCategorytype, setPocCategorytype] = useState([]);
  const [typeOfFiles, setTypeOfFiles] = useState("");
  const [name, setFileName] = useState("slide_deck");
  const [showLoader, setShowLoader] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [value, setValue] = useState("learnings");



  // useEffects
  useEffect(() => {
    renderData();
  }, []);

  const attachfile = (files) => {
    if (files.length > 0) {
      setSelectedFile(files[0]);
      let fileObject = JSON.stringify(files[0]);
      let tmp2 = fileObject.split('"');
      let fileName = tmp2[3];
      const arr_file_name = fileName.split(".");
      setFileName(arr_file_name[0]);
      setTypeOfFiles(arr_file_name[1]);
    }
  };

  const handleRadioChange = (event) => {
    setValue(event.target.value);
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleDeleteIcon = () => {
    setSelectedFile(null);
  };

  const RadioWithDropdown = () => {
    const [selectedOption, setSelectedOption] = useState("");
    const handleOptionChange = (event) => {
      setSelectedOption(event.target.value);
    }
  };
  
  const renderData = async () => {
    dispatch(SHOW_LOADER());
    var learn_raw = await SDCloudBackendAPIWithToken().get(Api.getResource);
    var category = CMSAPIWithToken()
      .get(Api.get_category)
      .then((resp) => resp.data)
      .then((data) => {
        let ctgy = data["category_types"];
        setCategorytype(ctgy);
      });

    var poccategory = CMSAPIWithToken()
      .get(Api.poc_get_category)
      .then((resp) => resp.data)
      .then((data) => {
        let ctgy = data["category_types"];
        setPocCategorytype(ctgy);
      });

    let learn = learn_raw.data.learnings;
    {
      let purposes = learn.map((item) => {
        if (item["is_scripted_demo"] == false) {
          return <option value={item["name"]}>{item["display_name"]}</option>;
        }
      });
      setMenuItems([<option value={null}>None</option>, ...purposes]);
    }
    let sandboxes = learn_raw.data.sandboxes;
    console.log(sandboxes)
    {
      let pocpurposes = sandboxes.map((item) => {
          return <option value={item["name"]}>{item["display_name"]}</option>;
      });
      setPocMenuItems([<option value={null}>None</option>, ...pocpurposes]);
    }
    dispatch(HIDE_LOADER());
  };
  const reset = () => {
    document.getElementById("watermark-form").reset();
  };
  const formik = useFormik({
    initialValues: {
      purpose: "",
      files: "",
      category_type: "",
    },
    onSubmit: (values) => {
      // //console.log("....",values)
      setShowLoader(true);

      let payload = {
        identifier: values.purpose,
        category_type: values.category_type,
      };

      const formData = new FormData();
      if (selectedFile) {
        formData.append("slide_deck", selectedFile, selectedFile.name);
        formData.append("category_type", payload.category_type);
        payload = formData;
        // If a file is selected, call the Slide Deck API
        SDCloudBackendAPIWithToken()
          .post("/export_slide_deck", payload, {
            responseType: "blob",
          })
          .then((response) => {
            setProgress(null);
            var blob = new Blob([response.data], {
              type: `application/${typeOfFiles}`,
            });
            var link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = payload.identifier
              ? payload.identifier + `.${typeOfFiles}`
              : `${name}.${typeOfFiles}`;
            link.click();
            setShowNotification(true);
            setNotificationMessage("Watermark content is downloaded.");
            setNotificationType("success");
            setBtnDisabled(false);
            setShowLoader(false);
            
          })
          .catch((err) => {
            let message = err.response.data.message;
            setShowNotification(true);
            setNotificationMessage("Resource not available");
            setNotificationType("error");
            setBtnDisabled(false);
            setShowLoader(false);
            
          });
      } else {

        // If no file is selected, call the CMS backend API
        var api_url = Api.watermarking
        if(value == "learnings"){
          api_url = Api.watermarking
        }
        if(value == "sandboxes"){
          api_url = Api.pocwatermarking
        }
        SDCloudCustomizeAPIWithToken()
          .post(api_url, payload, {
            responseType: "blob",
          })
          .then((response) => {
            setProgress(null);
            var blob = new Blob([response.data], {
              type: `application/${typeOfFiles}`,
            });
            var link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = payload.identifier
              ? payload.identifier + `.${typeOfFiles}`
              : `${name}.${typeOfFiles}`;
            link.click();
            setShowNotification(true);
            setNotificationMessage("Watermark content is downloaded.");
            setNotificationType("success");
            setBtnDisabled(false);
            setShowLoader(false);
          })
          .catch((err) => {
            let message = err.response.data.message;
            setShowNotification(true);
            setNotificationMessage("Resource not available");
            setNotificationType("error");
            setBtnDisabled(false);
            setShowLoader(false);
          });
      }
    },
    validate: (values) => {
      let errors = {};
      if (!values.category_type && !values.purpose) {
        errors.category_type = "Required";
      }
      return errors;
    },
  });
  return (
    <div>
      <Grid container>
        {showNotification ? (
          <Alert
            onClose={() => setShowNotification(false)}
            severity={notificationType}
            style={{
              width: "80%",
              borderRadius: "0.5rem",
              marginBottom: "-0.5rem",
            }}
          >
            {notificationMessage}
          </Alert>
        ) : null}
        <Grid container className={classes.filter}>
          <Grid item xs={12} md={12}>
            <FormControl fullWidth>
              <form onSubmit={formik.handleSubmit} id="watermark-form">
                <Grid container className={classes.gridContainer}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Typography variant="h4">Watermarking</Typography>
                  </Grid>
                  <br />
                  <p>Select item from the drop down</p>
                  <br />
                  {/* -------adding category dropdown------------ */}
                  <Grid
                    item
                    xs={12}
                    sm={4}
                    md={4}
                    style={{
                      width: "100%",
                      marginLeft: "55%",
                      marginBottom: "10px",
                    }}
                  >
                    {formik.errors.category_type &&
                    formik.touched.category_type ? (
                      <FormControl
                        fullWidth
                        className={classes.customInputStyle}
                        variant="filled"
                        style={{
                          marginTop: "10",
                          marginRight: "5px",
                          width: "82%",
                        }}
                      >
                        <InputLabel id="catgy">Category</InputLabel>
                        <Select
                          labelId="catgy"
                          id="category_type"
                          labelWidth={100}
                          style={{ width: "82%" }}
                          name="category_type"
                          value={formik.values.category_type}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          native
                          variant="filled"
                          helperText={formik.errors.category_type}
                          error={
                            formik.errors.category_type &&
                            formik.touched.category_type
                              ? true
                              : false
                          }
                        >
                          <option>select category</option>
                          {categorytype.map((value) => (
                            <option value={value}>{value}</option>
                          ))}
                        </Select>
                      </FormControl>
                    ) : (
                      <FormControl
                        fullWidth
                        className={classes.customInputStyle}
                        variant="filled"
                        style={{
                          marginTop: "11%",
                          marginRight: "25px",
                          width: "95%",
                        }}
                      >
                        <InputLabel id="catgy">Category</InputLabel>
                        <Select
                          labelId="catgy"
                          id="category_type"
                          labelWidth={100}
                          fullWidth
                          name="category_type"
                          value={formik.values.category_type}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          native
                          variant="filled"
                        >
                          <option>select category</option>
                          {categorytype.map((value) => (
                            <option value={value}>{value}</option>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  </Grid>
                  {/* <----------ending category---------> */}
                  <br />
                  <Grid item xs={12} md={12} className={classes.buttongrid}>
                      <FormControl component="fieldset">
                        <RadioGroup
                          row
                          value={value}
                          onChange={handleRadioChange}
                          className={classes.radioButton}
                        >
                          <FormControlLabel
                            value="learnings"
                            control={<Radio color="primary" />}
                            label="Learning Center"
                          />
                          <FormControlLabel
                            value="sandboxes"
                            control={<Radio color="primary" />}
                            label="Sandboxes"
                          />
                        </RadioGroup>
                      </FormControl>
                      <br /><br />
                    </Grid>
                <br /><br />
                {value === "learnings" && (
                  <Grid item xs={12} md={12}>
                    <Grid container justify="space-around">

                      <Grid item xs={12} md={12}>
                        <Grid item md={12}>
                          <Typography variant="h7">Select Learning Lab</Typography>
                        </Grid>
                        {btnDisabled ? (
                          <Loader
                            type={LOADER_TYPE}
                            color={theme.palette.secondary.main}
                            width={50}
                            height={50}
                            style={{ textAlign: "center" }}
                          />
                        ) : (
                          <FormControl
                            variant="filled"
                            className={classes.formControl}
                          >
                            <Grid container>
                              <Grid item xs={5} md={5}>
                                {formik.errors.purpose &&
                                formik.touched.purpose ? (
                                  <Select
                                    labelId="demo-simple-select-filled-label"
                                    id="demo-simple-select-filled"
                                    fullWidth
                                    name="purpose"
                                    // label="Select Cluster"

                                    onChange={formik.handleChange}
                                    native
                                    error
                                    helperText={formik.errors.purpose}
                                    className={classes.purposeDropdown}
                                  >
                                    {menuItems.length > 0 ? (
                                      menuItems
                                    ) : (
                                      <MenuItem value="">Select</MenuItem>
                                    )}
                                  </Select>
                                ) : (
                                  <Select
                                    labelId="demo-simple-select-filled-label"
                                    id="demo-simple-select-filled"
                                    fullWidth
                                    name="purpose"
                                    label="Select Cluster"
                                    value={formik.values.purpose}
                                    onChange={formik.handleChange}
                                    native
                                    className={classes.purposeDropdown}
                                  >
                                    {menuItems.length > 0 ? (
                                      menuItems
                                    ) : (
                                      <MenuItem value="">Select</MenuItem>
                                    )}
                                  </Select>
                                )}
                              </Grid>
                              <Grid item xs={2} md={2}>
                                <Typography
                                  variant="h4"
                                  style={{ textAlign: "center" }}
                                >
                                  OR
                                </Typography>
                              </Grid>

                              <Grid item xs={3} md={3}>
                                <DropzoneArea
                                  maxFileSize={100000000}
                                  acceptedFiles={[
                                    "application/pdf"
                                  ]}
                                  dropzoneText={".PDF"}
                                  showPreviews={false}
                                  onChange={(files) => attachfile(files)}
                                  onDelete={handleDeleteIcon}
                                  name="files"
                                  filesLimit={1}
                                />
                              </Grid>
                            </Grid>
                            {progress ? (
                              <Box
                                display="flex"
                                alignItems="center"
                                className={classes.progressBox}
                              >
                                <Box minWidth={35}>
                                  <Typography variant="body2">
                                    Uploading
                                  </Typography>
                                </Box>
                                <Box width="100%" mr={1}>
                                  <LinearProgress
                                    variant="determinate"
                                    className={classes.progressBar}
                                    classes={{
                                      barColorPrimary: classes.barColorPrimary,
                                    }}
                                    value={progress}
                                  />
                                </Box>
                                <Box
                                  minWidth={35}
                                  className={classes.progressBoxLabel}
                                >
                                  <Typography variant="body2">{`${progress}%`}</Typography>
                                </Box>
                              </Box>
                            ) : null}
                          </FormControl>
                        )}
                      </Grid>
                      <Grid
                        container
                        alignItems={"center"}
                        justify={"center"}
                        className={classes.buttongrid}
                      >
                        {showLoader ? (
                            <div 
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "center",
                              marginTop: "80px"
                            }}>
                            <LoaderHoc isLoading={showLoader}></LoaderHoc>
                          </div>
                          ):(
                            <>
                              <Grid item xs={12} md={3} spacing={1}>
                            <Button
                              variant="outlined"
                              color="secondary"
                              disableElevation
                              size="large"
                              fullWidth
                              disableFocusRipple
                              type="submit"
                              className={
                                classes.button + " " + classes.buttOutlined
                              }
                            >
                              Submit
                            </Button>
                          </Grid>
                            </>
                          )}
                      </Grid>
                    </Grid>
                  </Grid>
                )}
                {value === "sandboxes" && (
                   <Grid item xs={12} md={12}>
                    <Grid container justify="space-around">

                      <Grid item xs={12} md={12}>
                        <Grid item md={12}>
                          <Typography variant="h7">Select Sandbox</Typography>
                        </Grid>
                        {btnDisabled ? (
                          <Loader
                            type={LOADER_TYPE}
                            color={theme.palette.secondary.main}
                            width={50}
                            height={50}
                            style={{ textAlign: "center" }}
                          />
                        ) : (
                          <FormControl
                            variant="filled"
                            className={classes.formControl}
                          >
                            <Grid container>
                              <Grid item xs={5} md={5}>
                                {formik.errors.purpose &&
                                formik.touched.purpose ? (
                                  <Select
                                    labelId="demo-simple-select-filled-label"
                                    id="demo-simple-select-filled"
                                    fullWidth
                                    name="purpose"
                                    // label="Select Cluster"

                                    onChange={formik.handleChange}
                                    native
                                    error
                                    helperText={formik.errors.purpose}
                                    className={classes.purposeDropdown}
                                  >
                                    {pocMenuItems.length > 0 ? (
                                      pocMenuItems
                                    ) : (
                                      <MenuItem value="">Select</MenuItem>
                                    )}
                                  </Select>
                                ) : (
                                  <Select
                                    labelId="demo-simple-select-filled-label"
                                    id="demo-simple-select-filled"
                                    fullWidth
                                    name="purpose"
                                    label="Select Cluster"
                                    value={formik.values.purpose}
                                    onChange={formik.handleChange}
                                    native
                                    className={classes.purposeDropdown}
                                  >
                                    {pocMenuItems.length > 0 ? (
                                      pocMenuItems
                                    ) : (
                                      <MenuItem value="">Select</MenuItem>
                                    )}
                                  </Select>
                                )}
                              </Grid>
                              <Grid item xs={2} md={2}>
                                <Typography
                                  variant="h4"
                                  style={{ textAlign: "center" }}
                                >
                                  OR
                                </Typography>
                              </Grid>

                              <Grid item xs={3} md={3}>
                                <DropzoneArea
                                  maxFileSize={100000000}
                                  acceptedFiles={[
                                    "application/pdf"
                                  ]}
                                  dropzoneText={".PDF"}
                                  showPreviews={false}
                                  onChange={(files) => attachfile(files)}
                                  onDelete={handleDeleteIcon}
                                  name="files"
                                  filesLimit={1}
                                />
                              </Grid>
                            </Grid>
                            {progress ? (
                              <Box
                                display="flex"
                                alignItems="center"
                                className={classes.progressBox}
                              >
                                <Box minWidth={35}>
                                  <Typography variant="body2">
                                    Uploading
                                  </Typography>
                                </Box>
                                <Box width="100%" mr={1}>
                                  <LinearProgress
                                    variant="determinate"
                                    className={classes.progressBar}
                                    classes={{
                                      barColorPrimary: classes.barColorPrimary,
                                    }}
                                    value={progress}
                                  />
                                </Box>
                                <Box
                                  minWidth={35}
                                  className={classes.progressBoxLabel}
                                >
                                  <Typography variant="body2">{`${progress}%`}</Typography>
                                </Box>
                              </Box>
                            ) : null}
                          </FormControl>
                        )}
                      </Grid>
                      <Grid
                        container
                        alignItems={"center"}
                        justify={"center"}
                        className={classes.buttongrid}
                      >
                        {showLoader ? (
                            <div 
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "center",
                              marginTop: "80px"
                            }}>
                            <LoaderHoc isLoading={showLoader}></LoaderHoc>
                          </div>
                          ):(
                            <>
                              <Grid item xs={12} md={3} spacing={1}>
                            <Button
                              variant="outlined"
                              color="secondary"
                              disableElevation
                              size="large"
                              fullWidth
                              disableFocusRipple
                              type="submit"
                              className={
                                classes.button + " " + classes.buttOutlined
                              }
                            >
                              Submit
                            </Button>
                          </Grid>
                            </>
                          )}
                      </Grid>
                    </Grid>
                  </Grid>
                )}
                </Grid>
              </form>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Watermarking;
