import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { v4 as uuidv4 } from "uuid";

// Material UI Components
import { Alert } from "@material-ui/lab";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FreezeTable from "./FreezeStatusTable";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import { Switch } from "@material-ui/core";
import { SHOW_NOTIFICATION } from "action_creators/actioncreator";
// import Switch from '@mui/material/Switch';
// Icons
import NavigateNextIcon from "@material-ui/icons/NavigateNext";

// Local
import ViewInventory from "views/InventoryManagement/components/ViewInventory";
import RightDrawer from "views/Usermanagement/components/RightDrawer";

import {
  SDCloudBackendAPIWithToken,
  SDCloudCustomizeAPIWithToken,
} from "../../../apis/backendAPI";
import { Api } from "../../../config/api";
import {
  HIDE_LOADER,
  SHOW_LOADER,
} from "../../../action_creators/actioncreator";
import { useDispatch } from "react-redux";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import axios from "axios";
import { CMSAPIWithToken } from "../../../apis/CMS";

// Styling
const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "50px",
  },
  item: {
    textAlign: "end",
  },
  button: {
    background: `${theme.palette.buttonColor.main} !important`,
    height: "40px",
    color: "white",
    border: "none",
    width: "200px",
    fontSize: "0.875rem",
    fontFamily: `${theme.palette.fontList.selectedFont} !important`,
    cursor: "pointer",
  },
  buttongrid: {
    marginTop: "20px",
  },
  filter: {
    marginTop: "2%",
    padding: "20px 0px 50px 50px",
    background: "#FFFFFF",
    border: "1px solid #F0F0F0",
    boxSizing: "border-box",
    boxShadow: "0px 4px 12px rgb(0 0 0 / 4%)",
    position: "relative",
    width: "80%",
  },

  btnStyle: {
    background: `#FFFFFF`,
    height: "40px",
    width: "200px",
    fontSize: "0.875rem",
    fontFamily: `${theme.palette.fontList.selectedFont} !important`,
    cursor: "pointer",
  },
  filterButton: {
    padding: "8px 50px 0px 0px",
  },
  icon: {
    marginTop: "30px",
    // paddingLeft: "80px",
    // [theme.breakpoints.down("md")]: {
    //   marginTop: "26px",
    //   paddingLeft: "40px",
    // },
  },
  tableContainer: {
    marginTop: "40px",
  },
  statusEllipseRed: {
    width: "7px",
    height: "7px",
    display: "inline-block",
    background: "#E3241B",
    marginRight: "15px",
    borderRadius: "100%",
  },
  statusEllipseGreen: {
    width: "7px",
    height: "7px",
    display: "inline-block",
    background: "#3F704D",
    marginRight: "15px",
    borderRadius: "100%",
  },
}));

const TABLE_HEADERS = [
  {
    Header: "SKU ID",
    accessor: "skuid",
  },
  {
    Header: "SKU name",
    accessor: "template",
  },
  {
    Header: "Offering",
    accessor: "offering",
  },
  {
    Header: "Status",
    accessor: "status",
    sortable: false,
    filterable: false,
  },
];


const uploadFileToS3 = async (fileBlob, fileName) => {
  const s3FormData = new FormData();
  const file = new Blob([fileBlob], { type: fileBlob.type });
  s3FormData.append("attachment", file, fileName);
  s3FormData.append("path", "learnings"); // You can change this path based on your requirements

  try {
    const response = await SDCloudCustomizeAPIWithToken().post(Api.s3Upload, s3FormData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("user")}`,
      },
    });
    return response.data.urls[0]; // Return the S3 URL
  } catch (error) {
    console.error("Failed to upload file to S3:", error);
    throw error;
  }
};


const Freeze = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  // States
  const [rows, setRows] = useState([]);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState([]);
  const [notificationType, setNotificationType] = useState("");
  const [currentInventory, setCurrentInventory] = useState("");
  const [freezeTemplate, setFreezeTemplate] = useState("");
  const [menuItems, setMenuItems] = useState([]);
  const [offeringMenuItems, setOfferingMenuItems] = useState([]);
  const [dataTable, setDataTable] = useState([]);
  const [currentTemplate, setCurrentTemplate] = useState("");
  const [askInventoryInfo, setAskInventoryInfo] = useState(false);
  const [upgrade, setUpgrade] = useState(false);
  const [templateInfo, setTemplateInfo] = useState({});
  const [nextSkuID, setNextSkuID] = useState({});
  var offeringItems = ["Learnings", "Sandboxes", "Showcases", "Upgrade"];
  const [currentScreen, setCurrentScreen] = useState(offeringItems[0]);
  const [imagename, setImageName] = useState("");
  const [templateName, setTemplateName] = useState("");
  const [currentInventoryType, setCurrentInventoryType] = useState("");
  const [skuName, setSkuName] = useState("");
  const [idNum, setIdNum] = useState("");
  let domain = JSON.parse(localStorage.getItem("internal_domains"))?.domains;
  let userEmail = JSON.parse(localStorage.getItem("profileData"))?.email;
  let hasDomain = domain?.some((res) => userEmail.includes(res));
  const [isInternal, setIsInternal] = useState(hasDomain);
  const [auto, setAuto] = useState(false);
  const AntSwitch = withStyles((theme) => ({
    root: {
      width: 60,
      height: 33,
      padding: 0,
      marginTop: 6,
      display: "flex",
      boxShadow: "inset 0px 2px 2px -1px rgba(74, 74, 104, 0.1)",
      borderRadius: "24px",
    },
    switchBase: {
      padding: 2,
      paddingLeft: 4,
      color: "red",
      "&$checked": {
        transform: "translateX(24px)",
        backgroundColor: "transparent",
      },
    },
    thumb: {
      width: 28,
      height: 27,
      boxShadow: "none",
    },
    track: {
      borderRadius: 32,
      opacity: 1,
      backgroundColor: "#D8D8D8",
    },
    checked: {},
  }))(Switch);

  const toggleChange = (event) => {
    return new Promise((resolve, reject) => {
      if (event.target.checked) {
        let payload = {
          auto_provision: true,
          template_name: templateName,
        };
        SDCloudBackendAPIWithToken()
          .post("set_autoprovision_value/", payload)
          .then((res) => {
            resolve(res.data);
            setAuto(true);
            setShowNotification(true);
            setNotificationMessage("Auto Provision is now enabled");
            setNotificationType("success");
          })
          .catch((err) => {
            //console.log(err);
            let message = (
              <div style={{ width: "350px" }}>{err.response.data.message}</div>
            );
            const payload = {
              message: message,
              color: "danger",
            };
            payload.message && dispatch(SHOW_NOTIFICATION({ payload }));
            reject(err);
          });
      } else {
        let payload = {
          auto_provision: false,
          template_name: templateName,
        };
        SDCloudBackendAPIWithToken()
          .post("set_autoprovision_value/", payload)
          .then((res) => {
            resolve(res.data);
            setAuto(false);
            setShowNotification(true);
            setNotificationMessage("Auto Provision in now disabled");
            setNotificationType("error");
          })
          .catch((err) => {
            //console.log(err);
            let message = (
              <div style={{ width: "350px" }}>{err.response.data.message}</div>
            );
            const payload = {
              message: message,
              color: "danger",
            };
            payload.message && dispatch(SHOW_NOTIFICATION({ payload }));

            reject(err);
          });
      }
    });
  };

  const fetchImageDetails = (selectedCluster) => {
    //console.log(selectedCluster,"nmae")
    let payload = { name: selectedCluster };
    SDCloudBackendAPIWithToken()
      .post("get_image_details/", payload)
      .then((resp) => resp.data)
      .then((data) => {
        let image_details = data.image_details;
        formik.setFieldValue("imagename", image_details["image_name"]);

        setImageName(image_details["image_name"]);
        setTemplateName(image_details["template_name"]);
        setCurrentInventoryType(image_details["current_offering_type"]);
        setSkuName(image_details["sku_name"]);
        setIdNum(image_details["sku_id"]);
      })
      .catch((err) => {
        dispatch(HIDE_LOADER());
        let message = err.response.data.message;
        setShowNotification(true);
        setNotificationMessage(message);
        setNotificationType("error");
      });
  };
  // useEffects
  useEffect(() => {
    renderData();
  }, []);

  const handleAddInventory = (skuId, skuType, template) => {
    if (skuId != null) {
      setOpenDrawer(true);
      setCurrentInventory(skuId);
      setCurrentScreen(skuType);
      setFreezeTemplate(template);
    } else {
      alert(
        "Please add this product to the inventory and try to freeze the cluster."
      );
    }
  };

  const renderDrawerComponent = () => {
    let props = {
      sku_id: currentInventory,
      inv_type: currentScreen,
      freeze_template: freezeTemplate,
    };
    return <ViewInventory {...props} />;
  };

  const renderData = async () => {
    dispatch(SHOW_LOADER());
    var design_raw = await SDCloudBackendAPIWithToken().get(Api.getReadyLabCreationTemplates);
    var pocs_raw = await SDCloudBackendAPIWithToken().get(
      Api.getActiveClusters("none"),
      { "Content-Type": "application/json" }
    );
    var freezeClusters = await SDCloudBackendAPIWithToken().get(
      Api.getActiveClusters("freeze")
    );

    var get_sku_id = await SDCloudBackendAPIWithToken().get(
      Api.getInventoryOrder
    );
    setNextSkuID(get_sku_id.data);
    
    freezeClusters = freezeClusters.data.freezing_clusters;
    freezeClusters = freezeClusters.reverse();
    let pocs = pocs_raw.data["clouds"];
    let learn = pocs_raw.data["learnings"];
    let designs = design_raw.data.templates;
    pocs = pocs.concat(learn);
    pocs = pocs.concat(designs);
    let data = [];
    let info = [];
    let purposes = pocs.map((item) => {
      if (item["status"] == "ready") {
        if (item.cluster_uuid) {
          info.push(item["uuid"]);
          return (
            <option value={item["uuid"]}>
              {item["name"]} - {item["uuid"]}
            </option>
          );
        } else {
          return (
            <option value={item["uuid"]}>
              {item["clustername"]} - {item["uuid"]}
            </option>
          );
        }
      } else if (item["lab_status"] == "ready") {
        return (
          <option value={item["lab_uuid"]}>
            {item["display_name"]} - {item["lab_uuid"]}
          </option>
        );
      }
    });
    setTemplateInfo(info);
    let freezeData = freezeClusters.map((item) => {
      if(item.sku_id){
        data.push({
          skuid: item["sku_id"],
          template: item["sku_name"],
          image_name: item["image_name"],
          offering: item["offering"],
          status: (
            <div>
              <Grid container wrap="wrap" spacing={1}>
                {item["status"] != "frozen" ? (
                  <>
                  <Grid item>{item["status"]}</Grid>
                  <Grid item>
                    <p
                      className={classes.statusEllipseRed}
                      style={{ marginBottom: "10px" }}
                    ></p>
                  </Grid>
                  </>
                ) : null}
                {item["status"] == "frozen" ? (
                  <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    disableElevation
                    size="medium"
                    //   fullWidth
                    disableFocusRipple
                    onClick={() =>
                      handleAddInventory(
                        item["sku_id"],
                        item["offering"],
                        item["freeze_template"]
                      )
                    }
                    endIcon={<NavigateNextIcon />}
                  >
                    Finish
                  </Button>
                </Grid>
                ) : null}
              </Grid>
            </div>
          ),
        });
    }
    });
    setDataTable(data);
    setMenuItems([<option value={null}>Select</option>, ...purposes]);
    dispatch(HIDE_LOADER());
  };

  const formik = useFormik({
    initialValues: {
      imagename: "",
      purpose: "",
      skuid: "",
      skuname: "",
      clusterName: "",
      offering: "Learnings",
      image_description: "",
    },
    validate: (values) => {
      let errors = {};
      // if (staticIpRegex.test(values.imagename) && !askInventoryInfo) {
      // } else {
      //   errors.imagename =
      //     "String must be in small letters with no special characters except '-'";
      // }

      // if (!values.imagename && !askInventoryInfo) {
      //   errors.imagename = "Required";
      // }
      let r = /^([a-z0-9-]+)$/;
      let space_test = /\s/;
      // if(!r.test(values.imagename) && !askInventoryInfo){
      //   errors.imagename = "The image name should be lowercase alpha numeric, no special characters except '-'"
      // }
      // if(values.imagename.endsWith('-')){
      //   errors.imagename = "The image name should not end with -"
      // }
      // if (!values.skuname) {
      //   errors.skuname = "Required";
      // }
      let skuname = /^([a-z0-9-]+)$/;
      if (!upgrade && !r.test(values.skuname)) {
        errors.skuname = "Only lowercase alphanumeric and hypen is allowed";
      }
      
      if(!upgrade && space_test.test(values.skuname)){
        errors.skuname = "Space is not allowed";
      }
      
      if (!values.image_description) {
        errors.image_description = "Required";
      }
      return errors;
    },
    onSubmit: async (values) => {
      if (!askInventoryInfo && values.offering == "Upgrade") {
         // Step 1: Call the API to convert the SVG topology
         const response = await SDCloudBackendAPIWithToken().get("/designer/convert_and_download_topology", {
          params: {
            format: "svg", // Adjust format as needed
            uuid: values.purpose,
            template_name: values.skuname,
          },
          responseType: "blob", // Expecting a blob response
        });
      
      
      const s3Url = await uploadFileToS3(response.data, `${values.skuname}-topology.svg`);


        let payload = {
          image_name: values.imagename,
          uuid: values.purpose,
          offering: values.offering,
          sku_id: values.skuid,
          image_description: values.image_description,
          access_details: `<img src="${s3Url}">`,
        };
        //console.log(payload)
        dispatch(SHOW_LOADER());
        SDCloudCustomizeAPIWithToken();
        axios
          .post(Api.freeze, payload, {
            headers: {
              "Content-type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("user")}`,
            },
          })
          .then((response) => {
            dispatch(HIDE_LOADER());
            setShowNotification(true);
            setNotificationMessage(response.data.message);
            setNotificationType("success");
          })
          .catch((err) => {
            dispatch(HIDE_LOADER());
            let message = err.response.data.message;
            setShowNotification(true);
            setNotificationMessage(message);
            setNotificationType("error");
          });
      } else {
        
          // Step 1: Call the API to convert the SVG topology
          const response = await SDCloudBackendAPIWithToken().get("/designer/convert_and_download_topology", {
            params: {
              format: "svg", // Adjust format as needed
              uuid: values.purpose,
              template_name: values.skuname,
            },
            responseType: "blob", // Expecting a blob response
          });
        
        
        const s3Url = await uploadFileToS3(response.data, `${values.skuname}-topology.svg`);

        let final_sku_id;
        if (values.offering == "Learnings") {
          final_sku_id = nextSkuID["Learnings"];
        } else if (values.offering == "Showcases") {
          final_sku_id = nextSkuID["Showcases"];
        } else if (values.offering == "Sandboxes") {
          final_sku_id = nextSkuID["Sandboxes"];
        }
        let payload = {
          cluster_uuid: values.purpose,
          action: "freeze",
          sku_id: final_sku_id,
          sku_name: values.skuname,
          offering: values.offering,
          image_name: values.skuname + "-v1",
          image_description: values.image_description,
        };
        //console.log(payload)
        let learnpayload = {
          short_title: values.skuname,
          full_title: values.skuname,
          icon: "",
          identifier: values.skuname,
          description: "",
          type: "Learning",
          stage: "",
          category: values.offering,
          active: true,
          show_ip_details: false,
          email_content: '',
          access_details: `<img src="${s3Url}">`,
          lab_run_time: '',
          image: '',
          enable_suspend_resume: false,
          auto_lab_provisioning: false,
          enable_guacamole: false,
          is_scripted_demo: false,
          admin_lab_types_id: 1,
          access_impairment: false,
          access_tcpreplay: false,
          enable_export: false,
          account: [],
        };
        let showcasepayload = {
          short_title: values.skuname,
          full_title: values.skuname,
          icon: "icons/learnings/certification.png",
          identifier: values.skuname,
          access_details: `<img src="${s3Url}">`,
          image: "",
          description: "",
          active: true,
          show_ip_details: false,
          email_content: "",
          enable_suspend_resume: false,
          auto_lab_provisioning: false,
          enable_guacamole: false,
          lab_run_time: "",
          admin_lab_types_id: 2,
          access_impairment: false,
          access_tcpreplay: false,
          demo_layout: "",
          demo_html: "",
          demo_script: "",
          enable_export: false,
          account: [],
        };
        let sandbox_payload = {
          title: values.skuname,
          subtitle: values.skuname,
          identity: values.skuname,
          price: "",
          price_unit: "",
          description: "",
          diagram: "",
          diagram_img: "",
          icon_image: "",
          email_content: "",
          is_enabled: true,
          access_impairment: true,
          access_tcpreplay: true,
          account: [],
        };

        let url, cmspayload;
        let showcases_url = "/sales/admin_sales_demos/";
        let sandbox_url = "/poc/admin_sandboxes/";
        let learn_url = "/learnings/admin_learnings/";
        let sandbox_detil_url = "/poc/admin_sandboxes_details/";
        if (values.offering == "Sandboxes") {
          url = sandbox_url;
          cmspayload = sandbox_payload;
        }
        if (values.offering == "Learnings") {
          url = learn_url;
          cmspayload = learnpayload;
        }
        if (values.offering == "Showcases") {
          url = showcases_url;
          cmspayload = showcasepayload;
        }

        SDCloudCustomizeAPIWithToken()
          .post(Api.freezeTemplate(values.purpose), payload, {
            headers: {
              "Content-type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("user")}`,
            },
          })
          .then((response) => {
            //creating the cms dummy
            CMSAPIWithToken()
                .post(url,cmspayload)
                .then((resp) => {
                  let detailpayload = {
                    admin_sandboxes_id: resp.data.id,
                    overview: '',
                    node_details: '',
                    interface_details: '',
                    access_details: '',
                    clusterip_details: '',
                    access_device: `<img src="${s3Url}">`,
                    show_sandbox_details: true,
                    ip_details_access: '',
                    show_ip_details_access: true,
                  };
                  CMSAPIWithToken().post(sandbox_detil_url,detailpayload).then().catch()
                    }
                ).catch((err) => {
                //console.log('failed to create CMS entry')
            });
            dispatch(HIDE_LOADER());
            setShowNotification(true);
            setNotificationMessage("Action initiated successfully");
            setNotificationType("success");
            setTimeout(function () {
              window.location.href =
                window.location.origin + "/contentmanagement?option=freeze";
            }, 4000);
          })
          .catch((err) => {
            console.error(err);
            dispatch(HIDE_LOADER());
            //console.log(err.response);
            let message = err.response.data.message;
            setShowNotification(true);
            setNotificationMessage(message);
            setNotificationType("error");
          });
      }
    },
  });
  return (
    <div>
      <Grid container>
        {showNotification ? (
          <Alert
            onClose={() => setShowNotification(false)}
            severity={notificationType}
            style={{
              width: "80%",
              borderRadius: "0.5rem",
              marginBottom: "-0.5rem",
            }}
          >
            {notificationMessage}
          </Alert>
        ) : null}
        <Grid container className={classes.filter}>
          <Grid item xs={12} md={12}>
            <FormControl fullWidth>
              <form onSubmit={formik.handleSubmit}>
                <Grid container className={classes.gridContainer}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Typography variant="h4">Lab creation</Typography>
                  </Grid>
                  <br />
                  <p>Select cluster from the drop down</p>
                  <br />
                  <br />
                  <Grid item xs={12} md={12}>
                    <Grid container justify="space-around">
                      <Grid item xs={12} md={5}>
                        <Grid item md={12}>
                          <Typography variant="h7">Select Cluster</Typography>
                        </Grid>
                        {
                          <FormControl
                            variant="filled"
                            fullWidth
                            className={
                              (classes.formControl, classes.purposeDropdown)
                            }
                          >
                            {formik.errors.purpose && formik.touched.purpose ? (
                              <Select
                                labelId="demo-simple-select-filled-label"
                                id="demo-simple-select-filled"
                                fullWidth
                                name="purpose"
                                // label="Select Cluster"
                                value={formik.values.purpose}
                                onChange={formik.handleChange}
                                native
                                error
                                helperText={formik.errors.purpose}
                                className={classes.purposeDropdown}
                              >
                                {menuItems.length > 0 ? (
                                  menuItems
                                ) : (
                                  <MenuItem value="">Select</MenuItem>
                                )}
                              </Select>
                            ) : (
                              <Select
                                labelId="demo-simple-select-filled-label"
                                id="demo-simple-select-filled"
                                fullWidth
                                name="purpose"
                                label="Select Cluster"
                                value={formik.values.purpose}
                                onChange={(e) => {
                                  let index = e.target.selectedIndex;
                                  if (templateInfo.includes(e.target.value)) {
                                    setCurrentInventoryType('Designer')
                                    setAskInventoryInfo(true);
                                    setUpgrade(false);                          
                                    setOfferingMenuItems(
                                      offeringItems
                                        .filter((item) => item !== "Upgrade")
                                        .map((item) => {
                                          return (
                                            <option key={uuidv4()} value={item}>
                                              {item}
                                            </option>
                                          );
                                        })
                                    );
                                  } else {
                                    if(e.target.value !== "Select"){
                                      fetchImageDetails(e.target.value);
                                    }
                                    setAskInventoryInfo(false);
                                    setOfferingMenuItems(
                                      offeringItems.map((item) => {
                                        return (
                                          <option key={uuidv4()} value={item}>
                                            {item}
                                          </option>
                                        );
                                      })
                                    );
                                    
                                  }
                                  formik.setFieldValue(
                                    "purpose",
                                    e.target.value
                                  );
                                }}
                                native
                                className={classes.purposeDropdown}
                              >
                                {menuItems.length > 0 ? (
                                  menuItems
                                ) : (
                                  <MenuItem value="">Select</MenuItem>
                                )}
                              </Select>
                            )}
                          </FormControl>
                        }
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={5}
                        container
                        spacing={1}
                        style={{ marginRight: "1%" }}
                      >
                        <Grid item xs={6}>
                          <Typography variant="h7">
                            Current offering type
                          </Typography>
                          <TextField
                            type="text"
                            variant="filled"
                            fullWidth
                            value={currentInventoryType}
                            onBlur={formik.handleBlur}
                            disabled={true}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant="h7">
                            Select offering type
                          </Typography>
                          {
                            <FormControl
                              variant="filled"
                              fullWidth
                              className={
                                (classes.formControl, classes.purposeDropdown)
                              }
                            >
                              {formik.errors.purpose &&
                              formik.touched.purpose ? (
                                <Select
                                  labelId="demo-simple-select-filled-label"
                                  id="demo-simple-select-filled"
                                  fullWidth
                                  name="offering"
                                  // label="Select Cluster"
                                  value={formik.values.offering}
                                  onChange={formik.handleChange}
                                  native
                                  error
                                  helperText={formik.errors.offering}
                                  className={classes.purposeDropdown}
                                >
                                  {offeringMenuItems.length > 0 ? (
                                    offeringMenuItems
                                  ) : (
                                    <MenuItem value="">Select</MenuItem>
                                  )}
                                </Select>
                              ) : (
                                <Select
                                  labelId="demo-simple-select-filled-label"
                                  id="demo-simple-select-filled"
                                  name="offering"
                                  size={10}
                                  label="Select Cluster"
                                  value={formik.values.offering}
                                  onChange={(e) => {
                                    let index = e.target.selectedIndex;
                                    let newValue =
                                      e.target.options[index].value;
                                    formik.setFieldValue("offering", newValue);
                                    if (newValue == "Upgrade") {
                                      setUpgrade(true);
                                    } else {
                                      setUpgrade(false);
                                    }
                                  }}
                                  native
                                  className={classes.purposeDropdown}
                                >
                                  {offeringMenuItems.length > 0 ? (
                                    offeringMenuItems
                                  ) : (
                                    <MenuItem value="">Select</MenuItem>
                                  )}
                                </Select>
                              )}
                            </FormControl>
                          }
                        </Grid>
                      </Grid>

                      <Grid
                        container
                        justify="space-around"
                        style={{ paddingTop: "15px" }}
                      >
                        <Grid item xs={12} md={5}>
                          <Grid item xs={12}>
                            <Typography variant="h7">SKU ID</Typography>
                          </Grid>
                          {formik.errors.skuid && formik.touched.skuid ? (
                            <TextField
                              type="text"
                              variant="filled"
                              // label="Image Name"
                              name="skuid"
                              fullWidth
                              value={upgrade ? idNum : formik.values.skuid}
                              disabled={true}
                              onChange={formik.handleChange}
                              helperText={formik.errors.skuid}
                              onBlur={formik.handleBlur}
                              error
                            />
                          ) : (
                            <TextField
                              type="text"
                              variant="filled"
                              // label="Image Name"
                              name="skuid"
                              fullWidth
                              value={upgrade ? idNum : formik.values.skuid}
                              disabled={true}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                          )}
                        </Grid>
                        <Grid item xs={12} md={5}>
                          <Grid item xs={12}>
                            <Typography variant="h7">SKU name</Typography>
                          </Grid>
                          {formik.errors.skuname && formik.touched.skuname ? (
                            <TextField
                              type="text"
                              variant="filled"
                              // label="Image Name"
                              name="skuname"
                              fullWidth
                              value={
                                upgrade ? skuName : formik.values.skuname
                              }
                              disabled={upgrade ? true : false}
                              onChange={formik.handleChange}
                              helperText={formik.errors.skuname}
                              onBlur={formik.handleBlur}
                              error
                            />
                          ) : (
                            <TextField
                              type="text"
                              variant="filled"
                              // label="Image Name"
                              name="skuname"
                              fullWidth
                              value={
                                upgrade ? skuName : formik.values.skuname
                              }
                              disabled={upgrade ? true : false}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                          )}
                        </Grid>
                      </Grid>

                      <Grid
                        container
                        justify="space-around"
                        style={{ paddingTop: "15px" }}
                      >
                        <Grid item xs={12} md={5}>
                          {/* Description field */}
                          <Grid item xs={12}>
                            <Typography variant="h7">Description</Typography>
                          </Grid>
                          {formik.errors.image_description &&
                          formik.touched.image_description ? (
                            <TextField
                              type="text"
                              variant="filled"
                              name="image_description"
                              fullWidth
                              value={formik.values.image_description}
                              onChange={formik.handleChange}
                              helperText={formik.errors.image_description}
                              onBlur={formik.handleBlur}
                              error
                            />
                          ) : (
                            <TextField
                              type="text"
                              variant="filled"
                              fullWidth
                              name="image_description"
                              value={formik.values.image_description}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                          )}
                        </Grid>
                        <Grid item xs={12} md={5} spacing={2}>
                          <Grid container item xs={12}>
                            <Grid item xs={12}>
                              <Typography variant="h7">Image name</Typography>
                            </Grid>
                            {formik.errors.imagename &&
                            formik.touched.imagename ? (
                              <TextField
                                type="text"
                                variant="filled"
                                // label="Image Name"
                                name="imagename"
                                fullWidth
                                value={formik.values.imagename}
                                onChange={formik.handleChange}
                                helperText={formik.errors.imagename}
                                onBlur={formik.handleBlur}
                                disabled={true}
                                error
                              />
                            ) : (
                              <TextField
                                type="text"
                                variant="filled"
                                // label="Image Name"
                                name="imagename"
                                fullWidth
                                value={
                                  formik.values.skuname
                                    ? formik.values.skuname + "-v1"
                                    : formik.values.imagename
                                }
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                disabled={true}
                              />
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                      {/* Auto Provision field */}
                      {upgrade ? (
                        <Grid
                          container
                          justify="space-around"
                          style={{ paddingTop: "15px" }}
                        >
                          <Grid item xs={12} md={5}>
                            <Grid item md={12}>
                              <Typography variant="h7">
                                Auto Provision
                              </Typography>
                              <AntSwitch
                                name="auto_provision"
                                onChange={toggleChange}
                                checked={auto}
                                disabled={!formik.values.purpose}
                              />
                            </Grid>
                          </Grid>
                          <Grid item xs={12} md={5}></Grid>
                        </Grid>
                      ) : null}

                      <Grid
                        container
                        alignItems={"center"}
                        justify={"center"}
                        className={classes.buttongrid}
                      >
                        <Grid item xs={12} md={5} spacing={1}>
                          <Button
                            variant="outlined"
                            color="secondary"
                            disableElevation
                            className={classes.btnStyle}
                            size="large"
                            fullWidth
                            disableFocusRipple
                            // className={classes.button+' '+classes.buttOutlined}
                          >
                            Cancel
                          </Button>
                        </Grid>
                        <Grid item xs={12} md={3} spacing={1}>
                          <Button
                            variant="outlined"
                            color="secondary"
                            disableElevation
                            size="large"
                            fullWidth
                            disableFocusRipple
                            type="submit"
                            className={
                              classes.button + " " + classes.buttOutlined
                            }
                          >
                            Submit
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            </FormControl>
          </Grid>
          <Grid container className={classes.tableContainer}>
            <FreezeTable
              data={dataTable}
              // filterable
              columns={TABLE_HEADERS}
              // defaultPageSize={5}
              // showPaginationTop
              // showPaginationBottom={false}
              // className="-striped -highlight"
              tableTitle="Labs creation in progress"
              buttonTitle="Show details"
            />
          </Grid>
        </Grid>
        <RightDrawer
          open={openDrawer}
          onClose={setOpenDrawer}
          title={currentInventory}
        >
          {renderDrawerComponent()}
        </RightDrawer>
      </Grid>
    </div>
  );
};

export default Freeze;
